export default [
  {
    id: "1",
    date: "July 15",
    events: [
      {
        name: "Registration and Tent Pitching",
        startTime: "12:00 PM",
        endTime: "6:00 PM",
      },
      {
        name: "Workshop: Intro to Fire Starting",
        startTime: "6:00 PM",
        endTime: "7:00 PM",
      },
      {
        name: "Dinner: Guide to Wild Fungi Do‘s and Don‘ts",
        startTime: "7:00 PM",
        endTime: "8:00 PM",
      },
      {
        name: "Opening Ceremonies",
        startTime: "8:00 PM",
        endTime: "9:00 PM",
      },
      {
        name: "Activity: Spooky Stories",
        startTime: "10:00 PM",
        endTime: "11:00 PM",
      },
      {
        name: "Workshop: How to Hack the Trees",
        startTime: "11:00 PM",
        endTime: "12:00 AM",
      },
    ],
  },
  {
    id: "2",
    date: "July 16",
    events: [
      {
        name: "Midnight Snack: Trail Mix",
        startTime: "12:00 AM",
        endTime: "2:00 AM",
      },
      {
        name: "Workshop: Campsite Bear Defense Training",
        startTime: "4:00 AM",
        endTime: "6:00 AM",
      },
      {
        name: "Workshop: Foraging for Dummies",
        startTime: "6:00 AM",
        endTime: "7:00 AM",
      },
      {
        name: "Breakfast: The Runniest Scrambled Eggs You’ve Ever Had",
        startTime: "8:00 AM",
        endTime: "10:00 AM",
      },
      {
        name: "Workshop: Python Wrangling",
        startTime: "11:00 AM",
        endTime: "12:00 PM",
      },
      {
        name: "Lunch: Crushed Fruits On Rocks",
        startTime: "12:00 PM",
        endTime: "2:00 PM",
      },
      {
        name: "Activity: Coding Bootcamp",
        startTime: "2:00 PM",
        endTime: "3:00 PM",
      },
      {
        name: "Activity: High Ropes Challenge!",
        startTime: "3:00 PM",
        endTime: "4:00 PM",
      },
      {
        name: "Snack: Day-old S’mores",
        startTime: "4:00 PM",
        endTime: "5:00 PM",
      },
      {
        name: "Dinner: Bark Delight",
        startTime: "6:00 PM",
        endTime: "8:00 PM",
      },
      {
        name: "Activity: Gather ‘round the campfire to sing songs",
        startTime: "8:00 PM",
        endTime: "10:00 PM",
      },
      {
        name: "Activity: Pitch Black Bathroom Breaks",
        startTime: "10:00 PM",
        endTime: "11:30 PM",
      },
    ],
  },
  {
    id: "3",
    date: "July 17",
    events: [
      {
        name: "Midnight Snack: More Trail Mix (Sorry…)",
        startTime: "12:00 AM",
        endTime: "2:00 AM",
      },
      {
        name: "Workshop: Debugging your tent",
        startTime: "7:00 AM",
        endTime: "8:00 AM",
      },
      {
        name: "Breakfast: Eggs and Overcooked Bacon",
        startTime: "8:00 AM",
        endTime: "10:00 AM",
      },
      {
        name: "Judging",
        startTime: "10:00 AM",
        endTime: "12:00 PM",
      },
      {
        name: "Activity: Tinder au naturale",
        startTime: "12:00 PM",
        endTime: "1:00 PM",
      },
      {
        name: "Lunch: Bread with Spiderweb Spread",
        startTime: "1:00 PM",
        endTime: "2:00 PM",
      },
      {
        name: "Closing Ceremonies - Bonfire",
        startTime: "2:00 PM",
        endTime: "5:00 PM",
      },
    ],
  },
];
