import { trackGoal } from "fathom-client";
import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";
import tw from "twin.macro";

import { CheckmarkIcon, RightArrowIcon } from "src/assets/icons";
import { TWText } from "src/styles";

import "styled-components/macro";

import {
  validateEmailAddress,
  getResultMessage,
  signupRequest,
  SignUpState,
} from "./utils";

const MailingListSignup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [animate, setAnimate] = useState(false);
  const [signUpState, updateSignUpState] = useState(SignUpState.INITIAL);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (email !== "" && validateEmailAddress(email)) {
      signupRequest(email)
        .then(({ alreadySignup }) => {
          if (alreadySignup) {
            updateSignUpState(SignUpState.DUPLICATE);
            trackGoal("HPYDEHD8", 0);
          } else {
            updateSignUpState(SignUpState.SUBMITTED);
            trackGoal("1QYGDBQQ", 0);
          }
        })
        .catch(() => updateSignUpState(SignUpState.REJECTED))
        .finally(() => {
          setAnimate(true);
        });
    } else {
      updateSignUpState(SignUpState.INVALID);
      setAnimate(true);
      trackGoal("BD8YL3XI", 0);
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)} tw="mb-88">
      <div tw="flex items-center">
        <input
          aria-label="Email"
          css={[TWText.ctaDark]}
          tw="rounded-full pl-32 pr-[140px] h-[54px] w-[468px] tb:w-full tb:max-w-[468px]"
          type="text"
          placeholder="yogibear@email.com"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        ></input>
        <div tw="bg-primary-blue4 hover:bg-primary-blue5 text-white rounded-full ml-[-125px]">
          <ButtonWrapper
            animate={animate}
            isGreen={signUpState === SignUpState.SUBMITTED}
            onAnimationEnd={() => setAnimate(false)}
            tw="rounded-full"
          >
            <button css={[TWText.cta]} type="submit" tw="w-[120px] h-[44px]">
              <div tw="flex items-center justify-center">
                Sign up
                {signUpState === SignUpState.SUBMITTED ? (
                  <CheckmarkIcon tw="ml-8" />
                ) : (
                  <RightArrowIcon tw="ml-8" />
                )}
              </div>
            </button>
          </ButtonWrapper>
        </div>
      </div>
      <p css={[TWText.helper]} tw="mt-32 ml-4 tb:mt-16">
        {getResultMessage(signUpState)}
      </p>
    </form>
  );
};

const flashGreen = keyframes`
  from {
    background-color: #73A083;
  }
  to {
    background-color: transparent;
  }
`;

const flashRed = keyframes`
  from {
    background-color: #8B1212;
  }
  to {
    background-color: transparent;
  }
`;

const ButtonWrapper = styled.div<{
  animate?: boolean;
  isGreen?: boolean;
}>`
  animation: ${({ animate, isGreen }) =>
    animate
      ? css`
          ${isGreen ? flashGreen : flashRed} 2s linear
        `
      : "none"};
`;

export default MailingListSignup;
