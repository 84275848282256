type TQuestionAnswer = {
  question: string;
  answer: string;
};

export const QUESTION_SET: TQuestionAnswer[] = [
  {
    question: "What is Hack the Trees?",
    answer:
      "No wifi, no outlets, no cell service: just you, mother nature, and a tent. Showers are optional, but that‘s not much different from most hackathons.",
  },
  {
    question: "What kind of gear should I bring?",
    answer:
      "Sunscreen, seasonal clothes, and LOTS of bug spray! (bear spray wouldn‘t hurt either…)",
  },
  {
    question: "How will I charge my laptop?",
    answer: "Who says you‘re allowed to bring your laptop?",
  },
  {
    question: "Who can attend?",
    answer: "Anyone can attend, including bears.",
  },
  {
    question: "Do I have to share a tent?",
    answer: "Yes, so please refrain from any sus activities.",
  },
  {
    question: "Will there be free pizza?",
    answer: "All the ingredients to make it are freely found in *nature*",
  },
  {
    question: "What if I‘ve never camped before?",
    answer:
      "You‘ll probably be fine! Probably. Our workshops will teach you most of the basics that you‘ll need to know to survive.",
  },
  {
    question: "Is this legal?",
    answer: "Can you stop asking so many questions?",
  },
];
