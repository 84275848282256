import React from "react";
import tw from "twin.macro";

import {
  BearGrillsImg,
  ConnieImg,
  FireflyImg,
  HankTheTankImg,
  LesStroudImg,
} from "src/assets/img";
import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { SectionId } from "src/constants/section";
import { TWText } from "src/styles";
import "styled-components/macro";

const Judges: React.FC = () => (
  <SectionWrapper id={SectionId.JUDGES} tw="bg-primary-blue2">
    <ContentWrapper>
      <div tw="relative m-auto text-center">
        <FireflyImg tw="absolute bottom-0 rotate--90 tb:rotate-0 scale-125 tb:scale-100 -mb-32 tb:-mb-64 ml-[-6vw] tb:-ml-96" />
        <FireflyImg tw="absolute right-0 rotate--45 tb:rotate--120 scale-125 tb:scale-100 mt-16 tb:-mt-32 mr-[-10vw] tb:-mr-128" />
        <h1 css={[TWText.h1]} tw="mt-[85px] mb-[60px] tb:mb-[40px]">
          Judges
        </h1>
        <div tw="flex justify-center mb-64 md:flex-wrap">
          <div tw="flex flex-col flex-[50%] items-center mb-[60px] px-10 min-w-[256px]">
            <img
              alt="Bear Grills"
              src={BearGrillsImg}
              tw="mb-40 tb:mb-32"
            />
            <p css={[TWText.subheading]} tw="mb-16 tb:mb-8">
              Bear Grills
            </p>
            <p css={[TWText.body]}>
              Expert in survival.
              <br />
              Likes smokin’ meats.
            </p>
          </div>
          <div tw="flex flex-col flex-[50%] items-center mb-[60px] px-10 min-w-[256px]">
            <img
              alt="Les ‘Survivorman’ Stroud"
              src={LesStroudImg}
              tw="mb-40 tb:mb-32"
            />
            <p css={[TWText.subheading]} tw="mb-16 tb:mb-8">
              Les &rdquo;Survivorman&rdquo; Stroud
            </p>
            <p css={[TWText.body]}>
              Kinda upset that Bear
              <br />
              Grills was listed first.
            </p>
          </div>
          <div tw="flex flex-col flex-[50%] items-center mb-[60px] px-10 min-w-[256px]">
            <img alt="Connie" src={ConnieImg} tw="mb-40 tb:mb-32" />
            <p css={[TWText.subheading]} tw="mb-16 tb:mb-8">
              Connie
            </p>
            <p css={[TWText.body]}>
              An overly enthusiastic
              <br />
              camp counselor we found
              <br />
              wandering the woods.
            </p>
          </div>
          <div tw="flex flex-col flex-[50%] items-center mb-[60px] px-10 min-w-[256px]">
            <img alt="Hank the Tank" src={HankTheTankImg} tw="mb-40 tb:mb-32" />
            <p css={[TWText.subheading]} tw="mb-16 tb:mb-8">
              Hank the Tank
            </p>
            <p css={[TWText.body]}>
              An absolute unit, he is.
              <br />
              Not fat, just big boned.
            </p>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </SectionWrapper>
);

export default Judges;
