import React from "react";
import { styled } from "twin.macro";
import "twin.macro";
import "styled-components/macro";

import { TWText } from "src/styles";

const Grid = styled.div`
  /* Grid Fallback */
  display: flex;
  flex-wrap: wrap;

  /* Supports Grid */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  grid-gap: 1em;
`;

const Card = styled.div`
  width: 350px;
  height: 200px;
`;

const Colors: React.FC = (props) => (
  <div {...props}>
    <h1 css={[TWText.title]} tw="mb-8">
      Color Palette
    </h1>
    <h2 css={[TWText.h1]} tw="mb-8">
      Blues
    </h2>
    <Grid tw="mb-32">
      <Card tw="rounded-4 bg-primary-blue1 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          blue1
        </p>
      </Card>

      <Card tw="rounded-4 bg-primary-blue2 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          blue2
        </p>
      </Card>

      <Card tw="rounded-4 bg-primary-blue3 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          blue3
        </p>
      </Card>

      <Card tw="rounded-4 bg-primary-blue4 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          blue4
        </p>
      </Card>
    </Grid>
    <h3 css={[TWText.h2]} tw="mb-8">
      Secondary
    </h3>
    <Grid tw="mb-32">
      <Card tw="rounded-4 bg-secondary-brown1 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          brown1
        </p>
      </Card>

      <Card tw="rounded-4 bg-secondary-brown2 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          brown2
        </p>
      </Card>

      <Card tw="rounded-4 bg-secondary-brown3 flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          brown3
        </p>
      </Card>

      <Card tw="rounded-4 bg-secondary-grey flex justify-center align-items[center]">
        <p css={[TWText.h3]} tw="text-white">
          grey
        </p>
      </Card>
    </Grid>
  </div>
);

export default Colors;
