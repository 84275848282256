import { trackGoal } from "fathom-client";
import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { SOCIALS } from "src/constants/social";
import { TWText } from "src/styles";

import { SECTIONS } from "./constants";

const Menu: React.FC = () => {
  return (
    <div
      tw="flex justify-end w-screen gap-x-32 tb:gap-x-16 h-80 px-32"
      css={[TWText.h3]}
    >
      {Object.entries(SECTIONS).map(([id, name]) => {
        return (
          <div tw="self-center ml-16 mr-16" key={id}>
            <Link
              onClick={() => trackGoal("9DQ9WXL0", 0)}
              to={"/#" + id}
              css={[TWText.body]}
            >
              <span tw="hover:(underline)">{name}</span>
            </Link>
          </div>
        );
      })}
      {Object.entries(SOCIALS).map(([id, { icon, link, fathomEventCode }]) => (
        <div tw="self-center" key={id}>
          <a
            onClick={() => trackGoal(fathomEventCode, 0)}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon}
          </a>
        </div>
      ))}
    </div>
  );
};

export default Menu;
