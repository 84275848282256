import React from "react";
import { styled } from "twin.macro";
import { trackGoal } from "fathom-client";

import { CampImg, CampGlowImg, AuroraImg, StarsImg } from "src/assets/img";
import { MailingListSignup } from "src/components";
import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { SectionId } from "src/constants/section";
import { TWGradient, TWText } from "src/styles";
import "styled-components/macro";
import { deviceBreakpoints } from "src/utils/responsive";

const Section = styled.section`
  display: block;
`;

const Camp = styled(CampImg)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;

  @media ${deviceBreakpoints.tablet} {
    top: 15vh;
    left: unset;
    max-width: unset;
    right: 0;
    width: 200%;
    height: auto;
  }

  @media (max-width: 530px) {
    top: 40vh;
    left: unset;
    max-width: unset;
    right: 0;
    width: 230%;
    height: auto;
  }
`;

const CampGlow = styled(CampGlowImg)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  animation: pulse 3s infinite;

  @media ${deviceBreakpoints.tablet} {
    top: 15vh;
    left: unset;
    max-width: unset;
    right: 0;
    width: 200%;
    height: auto;
  }

  @media (max-width: 530px) {
    top: 40vh;
    left: unset;
    max-width: unset;
    right: 0;
    width: 230%;
    height: auto;
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  } 
`;

const Aurora = styled(AuroraImg)`
  position: absolute;
  top: -15vh;
  left: -8vw;
  max-width: 100%;

  @media ${deviceBreakpoints.tablet} {
    top: -1%;
    left: -60%;
    max-width: unset;
    width: 200%;
    height: auto;
  }
`;

const Stars = styled(StarsImg)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;

  @media ${deviceBreakpoints.tablet} {
    top: -10%;
    left: -120%;
    max-width: unset;
    width: 300%;
    height: auto;
  }
`;

const HeroSection: React.FC = () => (
  <SectionWrapper css={[TWGradient.hero]} tw="z-10" id={SectionId.HEROES}>
    <ContentWrapper tw="h-screen max-w-screen-xl lg:max-w-screen-hero flex items-center tb:block tb:h-[90vh]">
      <Section>
        <h2 css={[TWText.heroEyebrow]} tw="mt-96 tb:mt-80 ">
          PRESENTING
        </h2>
        <h1 css={[TWText.title]} tw="mt-17 mb-17">
          Hack the Trees
        </h1>
        <h2 css={[TWText.subheading]} tw="mb-36 lm:(text-18!) tb:mb-24">
          The world&apos;s first off-the-grid hackathon
        </h2>
        <h3 css={[TWText.cta]} tw="mb-93 tb:mb-48 tb:(text-16!) ">
          September 16th - 18th,&nbsp;
          <a 
            href="https://goo.gl/maps/vN7LWZZnR2KWtwnR6" 
            target="_blank" 
            rel="noreferrer"
            onClick={() => {
              trackGoal('4ERRHSWY', 0);
            }}
          >
            (45.8°, -78.4°)
          </a>
        </h3>
        <MailingListSignup />
      </Section>
    </ContentWrapper>
    <Camp
      width="100%"
      height="auto"
      viewBox="0 0 1440 817"
      overflow="visible"
      preserveAspectRatio="xMidYMid slice"
    />
    <CampGlow
      width="100%"
      height="auto"
      viewBox="0 0 1440 817"
      overflow="visible"
      preserveAspectRatio="xMidYMid slice"
    />
    <Stars
      width="100%"
      height="auto"
      viewBox="0 0 1440 817"
      overflow="visible"
      preserveAspectRatio="xMidYMid slice"
    />
    <Aurora
      width="100%"
      height="auto"
      viewBox="0 0 1440 817"
      overflow="visible"
      preserveAspectRatio="xMidYMid slice"
    />
  </SectionWrapper>
);

export default HeroSection;
