import React from "react";
import { styled } from "twin.macro";

import { FishBadgeImg, LoneBadgeImg, StackBadgeImg } from "src/assets/img";
import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { SectionId } from "src/constants/section";
import { TWText } from "src/styles";
import { deviceBreakpoints } from "src/utils/responsive";
import "styled-components/macro";

const Prizes: React.FC = () => (
  <SectionWrapper id={SectionId.PRIZES}>
    <ContentWrapper>
      <Section tw="relative w-full px-48 mb-128 text-center md:px-0 md:mb-0">
        <h2 css={[TWText.h1]}>Prizes</h2>
        <div tw="flex justify-between mt-64 md:flex-col md:items-center">
          <PrizeWrapper>
            <StackBadgeImg
              aria-label="Stack Overflow Badge"
              tw="w-[218px] h-[218px]"
            />
            <h3 css={[TWText.subheading]} tw="mt-32">
              Stack Overflow
            </h3>
            <p css={[TWText.body]} tw="mt-24">
              For the team with the highest
              <br />
              standing marshmallow tower!
            </p>
            <p css={[TWText.body]} tw="italic mt-20">
              Prize: a bag of marshmallows
            </p>
          </PrizeWrapper>
          <PrizeWrapper>
            <FishBadgeImg
              aria-label="Angler Investor Badge"
              tw="w-[218px] h-[218px]"
            />
            <h3 css={[TWText.subheading]} tw="mt-32">
              Angler Investor
            </h3>
            <p css={[TWText.body]} tw="mt-24">
              The individual who catches the
              <br />
              biggest fish for their team to eat.
            </p>
            <p css={[TWText.body]} tw="italic mt-20">
              Prize: a can of worms
            </p>
          </PrizeWrapper>
          <PrizeWrapper>
            <LoneBadgeImg
              aria-label="Lone Survivor Badge"
              tw="w-[218px] h-[218px]"
            />
            <h3 css={[TWText.subheading]} tw="mt-32">
              Lone Survivor
            </h3>
            <p css={[TWText.body]} tw="mt-24">
              Improvise. Adapt. Overcome.
              <br />
              No elaboration needed.
            </p>
            <p css={[TWText.body]} tw="italic mt-20">
              Prize: a ticket to australia
            </p>
          </PrizeWrapper>
        </div>
      </Section>
    </ContentWrapper>
  </SectionWrapper>
);

const PrizeWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 280px;
  @media (max-width: ${deviceBreakpoints.medium}px) {
    margin-bottom: 64px;
  }
`;

const Section = styled.section``;

export default Prizes;
