import React from "react";
import { styled } from "twin.macro";
import "styled-components/macro";

import { BowlOfMarshMallowsImg, FireflyImg } from "src/assets/img";
import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { SectionId } from "src/constants/section";
import { TWText } from "src/styles";

const About: React.FC = () => {
  return (
    <SectionWrapper tw="bg-primary-blue2 pt-80 -mt-80" id={SectionId.ABOUT}>
      <ContentWrapper>
        <Section tw="relative w-full overflow-hidden m-auto text-center">
          <h1 css={[TWText.h1]} tw="mt-48 mb-40 tb:mb-28">
            A hackathon in nature
          </h1>
          <p
            css={[TWText.subheading]}
            tw="tb:mb-0 tb:ml-8 tb:mr-8 mb-48 lm:font-weight[500]"
          >
            Hack the Trees is the world’s first ever off-the-grid hackathon!
            Take a break from the constant blue light exposure of hackathons and
            enjoy a night under the stars with plenty of activities from scary
            stories, to high-ropes, and more! Power down your workstation and
            power up with s’mores made right over the campfire. Welcome to the
            worlds’ first ever no-tech hackathon!
          </p>
          <div tw="flex justify-center mb-112 tb:mb-0">
            <FireflyImg tw=" tb:mr-16 sm:scale-125" />
            <BowlOfMarshMallowsImg tw="sm:scale-125" />
            <FireflyImg tw="rotate--120 tb:ml-16 sm:scale-125" />
          </div>
        </Section>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const Section = styled.section`
  max-width: 1000px;
`;

export default About;
