import { motion, useCycle } from "framer-motion";
import React, { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { styled } from "twin.macro";

import "styled-components/macro";
import { LogoIcon, LogoFilledIcon } from "src/assets/icons";
import { SectionId } from "src/constants/section";
import { useDeviceSize } from "src/utils";
import {
  useScrollDirection,
  ScrollDirection,
} from "src/utils/hooks/useScrollDirection";

import { NAVBAR_HEIGHT_PX, NAVBAR_COLOR } from "./constants";
import Menu from "./menu";
import MobileMenu from "./mobileMenu";
import MobileMenuHamburger from "./mobileMenuHamburger";

export type TContainerProps = {
  isScrolledDown: boolean;
  isHidden: boolean;
  background: string;
};

const Navbar: React.FC = () => {
  const scrollDirection = useScrollDirection({
    initialDirection: ScrollDirection.DOWN,
    thresholdPixels: 10,
  });
  const isMediumOrSmaller = useDeviceSize("medium");

  const [isScrolledDown, setScrolledDown] = useState(false);
  const [mobileMenuOpen, toggleMobileMenuOpen] = useCycle(false, true);

  useEffect(() => {
    /**
     * Change the appearance of the nav bar when a user
     * scrolls down the page.
     */
    const checkScrolledDown = () => {
      const hasScrolledDown = window.scrollY > 0;
      setScrolledDown(hasScrolledDown);
    };

    window.addEventListener("scroll", checkScrolledDown);
    checkScrolledDown();
    return () => window.removeEventListener("scroll", checkScrolledDown);
  }, []);

  const isHidden = isScrolledDown && scrollDirection === ScrollDirection.DOWN;
  const [logo, setLogo] = useState(<LogoIcon />);

  return isMediumOrSmaller !== undefined ? (
    <Container
      isHidden={isHidden}
      isScrolledDown={isScrolledDown}
      background={NAVBAR_COLOR}
    >
      <div
        tw="ml-20 mr-auto self-center"
        onMouseEnter={() => setLogo(<LogoFilledIcon />)}
        onMouseLeave={() => setLogo(<LogoIcon />)}
      >
        <Link to={"/#" + SectionId.HEROES}>{logo}</Link>
      </div>
      {isMediumOrSmaller ? (
        <motion.div
          animate={mobileMenuOpen ? "open" : "closed"}
          tw="z-50 mt-4 mr-16"
        >
          <MobileMenuHamburger toggle={() => toggleMobileMenuOpen()} />
        </motion.div>
      ) : (
        <Menu />
      )}
      {isMediumOrSmaller && (
        <MobileMenu
          isHidden={isHidden}
          isOpen={mobileMenuOpen}
          toggleIsOpen={toggleMobileMenuOpen}
        />
      )}
    </Container>
  ) : null;
};

const Container = styled.nav.attrs<TContainerProps>(
  ({ isScrolledDown, background, isHidden }) => ({
    style: {
      backgroundColor: isScrolledDown ? background : "transparent",
      transform: isHidden
        ? `translateY(-${NAVBAR_HEIGHT_PX}px)`
        : "translateY(0)",
    },
  })
)<TContainerProps>`
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 99;

  transition: background-color 250ms, transform 250ms;
`;

export default Navbar;
