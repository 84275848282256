import React from "react";
import tw from "twin.macro";

import "styled-components/macro";
import { TWText } from "src/styles";

type TProps = {
  name: string;
  startTime: string;
  endTime: string;
  shouldDisplayBorder: boolean;
};

export const EventRow: React.FC<TProps> = ({
  name,
  startTime,
  endTime,
  shouldDisplayBorder,
}) => {
  const borderCss = shouldDisplayBorder
    ? tw`border-b border-secondary-brown3`
    : "";

  return (
    <div
      tw="flex justify-between items-center pt-12 pb-4 text-white"
      css={borderCss}
    >
      <div css={[TWText.eventTitle]} tw="mr-10 text-left">
        {name}
      </div>
      <div
        css={[TWText.eventDateTitle]}
        tw="ml-10 text-right"
      >{`${startTime} - ${endTime}`}</div>
    </div>
  );
};
