import React from "react";
import "twin.macro";
import "styled-components/macro";

import PageWrapper from "src/components/base/Layout/PageWrapper";
import Colors from "src/sections/Playground/Colors";
import Typography from "src/sections/Playground/Typography";

const Playground: React.FC = () => (
  <PageWrapper>
    <div tw="flex justify-between">
      <Typography tw="mb-48" />
    </div>
    <Colors tw="mb-48" />
  </PageWrapper>
);

export default Playground;
