import React from "react";

import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwitterIcon,
} from "src/assets/icons";

export enum SocialPlatforms {
  FACEBOOK = "Facebook",
  INSTA = "Instagram",
  LINKEDIN = "LinkedIn",
  TWITTER = "Twitter",
}

type TSocialInfo = {
  icon: JSX.Element;
  link: string;
  fathomEventCode: string;
};

export const SOCIALS: Record<SocialPlatforms, TSocialInfo> = {
  [SocialPlatforms.FACEBOOK]: {
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/Hack-the-Trees-104191892251561",
    fathomEventCode: "FWY97PG7",
  },
  [SocialPlatforms.INSTA]: {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/hackthetrees",
    fathomEventCode: "ZEUHTT78",
  },
  [SocialPlatforms.LINKEDIN]: {
    icon: <LinkedinIcon />,
    link: "https://www.linkedin.com/company/hack-the-trees/",
    fathomEventCode: "7K0K6CJV",
  },
  [SocialPlatforms.TWITTER]: {
    icon: <TwitterIcon />,
    link: "https://twitter.com/hackthetrees",
    fathomEventCode: "IUKAEX8D",
  },
};
