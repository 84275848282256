import { deviceBreakpoints, TDeviceType } from "../responsive";

import { useWindowSize } from "./useWindowSize";

/**
 * A hook to determine the type of device the app is running on, based on the screen size.
 * @returns whether or not the screen is of type `device` or a list of all possible devices sorted descending by size
 */
export function useDeviceSize(device: TDeviceType): boolean | undefined;
export function useDeviceSize(): TDeviceType[] | undefined;
export function useDeviceSize(device?: TDeviceType) {
  const { windowWidth } = useWindowSize();

  if (windowWidth === undefined) {
    return windowWidth;
  }

  if (device) {
    return windowWidth <= deviceBreakpoints[device];
  }

  return Object.entries(deviceBreakpoints)
    .filter(([, width]) => width <= windowWidth)
    .map(([device]) => device) as TDeviceType[];
}
