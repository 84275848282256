import React from "react";
import { styled } from "twin.macro";

import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import "styled-components/macro";
import { SectionId } from "src/constants/section";
import { QUESTION_SET } from "src/copy/FAQ";
import { TWText } from "src/styles";

const FAQ: React.FC = () => (
  <SectionWrapper tw="bg-primary-blue2" id={SectionId.FAQ}>
    <ContentWrapper>
      <Section tw="relative w-full overflow-hidden m-auto">
        <h1 css={[TWText.h1]} tw="mt-96 mb-88 text-center">
          FAQs
        </h1>
        <div tw="grid grid-cols-2 gap-x-128 md:(block) mb-48">
          {QUESTION_SET.map((qa) => (
            <div
              key={qa.question}
              tw="mb-36 max-w-screen-md md:ml-auto md:mr-auto"
            >
              <p tw="mb-4" css={[TWText.subheading]}>
                {qa.question}
              </p>
              <p css={[TWText.body]}>{qa.answer}</p>
            </div>
          ))}
        </div>
      </Section>
    </ContentWrapper>
  </SectionWrapper>
);

const Section = styled.section`
  max-width: 1000px;
`;

export default FAQ;
