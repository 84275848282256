import { trackGoal } from "fathom-client";
import React, { useCallback, useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import tw from "twin.macro";

import {
  BearFishImg,
  CampsiteImg,
  ZBottomImg,
  ZCenterImg,
  ZTopImg,
} from "src/assets/img";
import { MailingListSignup } from "src/components";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { SectionId } from "src/constants/section";
import { SOCIALS } from "src/constants/social";
import { TWText } from "src/styles";

import "styled-components/macro";

const Footer: React.FC = () => {
  const [animate, setAnimate] = useState<boolean>(false);
  const [isMarshmallowToasted, setIsMarshmallowToasted] =
    useState<boolean>(false);

  const randomInterval = useCallback((skipAnimation?) => {
    if (!skipAnimation) {
      setAnimate(true);
    }
    const delay = Math.floor(Math.random() * 30) + 15;
    setTimeout(randomInterval, delay * 1000);
  }, []);

  useEffect(() => {
    randomInterval(true);
  }, [randomInterval]);

  const handleHover = () => {
    document.body.style.cursor =
      "url('/img/marshmallow-cursor-toasted.svg') 0 0, auto";
    trackGoal("EQBPBOPL", 0);
    setIsMarshmallowToasted(true);
  };

  return (
    <SectionWrapper tw="bg-primary-blue2" id={SectionId.FOOTER}>
      <div tw="relative w-screen overflow-hidden m-auto">
        <BearFishWrapper
          animate={animate}
          onAnimationEnd={() => setAnimate(false)}
        >
          <img
            alt="Bear fish"
            src={BearFishImg}
            tw="absolute w-[14vw] h-auto mt-[20vw] ml-[-14vw] tb:w-[100px] tb:mt-[170px] tb:ml-[-100px]"
          />
        </BearFishWrapper>
        <ZBottomImg
          aria-label="Z"
          tw="animate-[growBottom 6s linear infinite] absolute w-[3vw] h-auto mt-[25vw] ml-[47vw] tb:w-24 tb:mt-[190px] tb:ml-[calc(50vw - 25px)]"
        />
        <ZCenterImg
          aria-label="Z"
          tw="animate-[growCenter 6s linear infinite] absolute w-[2vw] h-auto mt-[23vw] ml-[50vw] tb:w-16 tb:mt-[175px]"
        />
        <ZTopImg
          aria-label="Z"
          tw="animate-[growTop 6s linear infinite] absolute w-[1vw] h-auto mt-[21vw] ml-[51vw] tb:w-8 tb:mt-[160px] tb:ml-[calc(50vw + 10px)]"
        />
        <CampsiteImg
          aria-label="Campsite"
          tw="relative w-full h-auto tb:w-[768px] tb:ml-[calc(50vw - 384px)]"
        />
        <div
          tw="absolute w-[8vw] h-[8vw] mt-[-6vw] ml-[59vw] rounded-full"
          onMouseEnter={() => (!isMarshmallowToasted ? handleHover() : null)}
        ></div>
        <div tw="bg-primary-blue3 w-full p-64 tb:px-[5vw] tb:pb-48">
          <h1 css={[TWText.h1]} tw="mb-12 tb:mb-4">
            nature awaits.
          </h1>
          <p css={[TWText.subheading]} tw="mb-40 tb:mb-24">
            are you up for the adventure?
          </p>
          <MailingListSignup />
          <div tw="flex items-center justify-between tb:justify-center">
            <div tw="tb:hidden flex space-x-32">
              {Object.entries(SOCIALS).map(([id, { icon, link }]) => (
                <div tw="self-center" key={id}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {icon}
                  </a>
                </div>
              ))}
            </div>
            <h3 css={[TWText.emphasis]}>Copyright © Hack the Trees 2022</h3>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

const move = keyframes`
  from {
    transform: translateX(125%);
  }
  to {
    transform: translateX(-25%);
  }
`;

const BearFishWrapper = styled.div<{
  animate?: boolean;
}>`
  animation: ${({ animate }) =>
    animate
      ? css`
          ${move} 3s linear
        `
      : "none"};
`;

export default Footer;
