import React from "react";
import { styled } from "twin.macro";

import { MarshmallowLeftImg, MarshmallowRightImg } from "src/assets/img";
import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { Workshop } from "src/components/workshops/Workshop";
import { SectionId } from "src/constants/section";
import { TWText } from "src/styles";
import { deviceBreakpoints } from "src/utils/responsive";
import "styled-components/macro";

const Workshops: React.FC = () => (
  <SectionWrapper tw="bg-primary-blue2" id={SectionId.WORKSHOPS}>
    <MarshmallowLeftImg tw="tb:hidden absolute -left-72 top-112 z-30" />
    <MarshmallowRightImg tw="tb:hidden absolute -right-80 top-64 z-30" />
    <ContentWrapper>
      <Section tw="relative text-center">
        <h1 css={[TWText.h1]} tw="mt-88 mb-88 tb:(mt-72 mb-64)">
          Workshops
        </h1>
        <div tw="flex flex-wrap gap-x-48 gap-y-72  mb-96 tb:(gap-y-48 mb-160) mb:(gap-y-0) justify-center">
          <Workshop
            title="Intro to Fire Starting"
            body="How are you supposed to eat dinner if you don‘t have anything to cook with? “Spark” your survival skills by learning how to make a campfire!"
          />
          <Workshop
            title="How to Hack the Trees"
            body="When‘s the last time you went outside? Time to conquer the wilds by literally learning how to Hack the Trees - we provide the axes!"
          />
          <Workshop
            title="Campsite Bear Defense Training"
            body='“Are there bears in these woods? Maybe.” Learn to defend your campsite from these cuddly (and occasionally violent) creatures!'
          />
          <Workshop
            title="Foraging for Dummies"
            body="Craving a snack? Go grab one from nature‘s pantry! This workshop will give you the rundown on the tastiest treats the woods has to offer."
          />
          <Workshop
            title="Python Wrangling"
            body="There‘s a snake in my boot! No, literally. Can you help me get it out? "
          />
          <Workshop
            title="Debugging your Tent"
            body="The name says it all - Make sure your bug spray is DEET free, or things might get out of hand."
          />
        </div>
      </Section>
    </ContentWrapper>
  </SectionWrapper>
);

const Section = styled.section`
  max-width: 1105px;
  @media (min-width: ${deviceBreakpoints.large}px) {
    width: 1105px;
  }
`;

export default Workshops;
