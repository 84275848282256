import React from "react";
import { styled } from "twin.macro";

import { CloudImg, WifiImg, TentImg } from "src/assets/img";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { ScheduleTab } from "src/components/Schedule/ScheduleTab";
import { SectionId } from "src/constants/section";
import { TWText } from "src/styles";
import { deviceBreakpoints } from "src/utils/responsive";
import "styled-components/macro";

const Schedule: React.FC = () => {
  return (
    <SectionWrapper id={SectionId.SCHEDULE} tw="bg-primary-blue2">
      <SWifiImg />
      <STentImg />
      <SCloudImg />
      <section tw="relative w-screen overflow-hidden text-center">
        <h1 css={[TWText.h1]} tw="mt-114 lm:(mt-70)">
          Schedule
        </h1>
        <ScheduleTab />
      </section>
    </SectionWrapper>
  );
};

const SWifiImg = styled(WifiImg)`
  position: absolute;
  right: 193px;
  top: 81px;

  @media (max-width: ${deviceBreakpoints.medium}px) {
    width: 120px;
    height: 120px;
    right: 65px;
    top: 110px;
  }

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    width: 100px;
    height: 100px;
    right: 20px;
    top: 125px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    display: none;
  }
`;

const STentImg = styled(TentImg)`
  position: absolute;
  left: 168px;
  top: 185px;

  @media (max-width: ${deviceBreakpoints.medium}px) {
    width: 150px;
    height: 150px;
    left: 30px;
    top: 180px;
  }

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    width: 120px;
    height: 120px;
    left: 5px;
    top: 165px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    display: none;
  }
`;

const SCloudImg = styled(CloudImg)`
  position: absolute;
  right: 170px;
  bottom: 22px;

  @media (max-width: ${deviceBreakpoints.medium}px) {
    width: 105px;
    height: 105px;
    right: 50px;
    bottom: 0px;
  }

  @media (max-width: ${deviceBreakpoints.tablet}px) {
    width: 90px;
    height: 90px;
    right: 5px;
  }

  @media (max-width: ${deviceBreakpoints.largeMobile}px) {
    display: none;
  }
`;

export default Schedule;
