import { trackGoal } from "fathom-client";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import tw, { styled } from "twin.macro";
import "styled-components/macro";

import { SOCIALS } from "src/constants/social";
import { TWText } from "src/styles";
import { useWindowSize } from "src/utils";

import {
  SECTIONS,
  NAVBAR_HEIGHT_PX,
  NAVBAR_COLOR,
  mobileBackgroundVariants,
  mobileListVariants,
  mobileItemVariants,
} from "./constants";

export type TMobileMenuProps = {
  isHidden: boolean;
  isOpen: boolean;
  toggleIsOpen: () => void;
};

// Credits: https://codesandbox.io/s/framer-motion-side-menu-mx2rw?fontsize=14&module=/src/Example.tsx&file=/src/Example.tsx
const MobileMenu: React.FC<TMobileMenuProps> = ({
  isHidden,
  isOpen,
  toggleIsOpen,
}) => {
  const containerRef = useRef(null);
  const { windowHeight } = useWindowSize();

  // disable scrolling when navbar open
  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "visible";

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  return (
    <Container
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={windowHeight}
      ref={containerRef}
      css={[isOpen ? tw`pointer-events-auto` : tw`pointer-events-none`]}
    >
      <Background variants={mobileBackgroundVariants}>
        <List variants={mobileListVariants}>
          {Object.entries(SECTIONS).map(([id, name], i) => {
            return (
              <ListItem
                key={i}
                variants={mobileItemVariants}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                css={[
                  isOpen
                    ? tw`pointer-events-auto`
                    : tw`pointer-events-none select-none`,
                ]}
              >
                <a
                  key={id}
                  href={`/#${id}`}
                  onClick={() => {
                    toggleIsOpen();
                    trackGoal("9DQ9WXL0", 0);
                  }}
                  tabIndex={isHidden ? -1 : 0}
                  aria-hidden={isHidden ? "true" : undefined}
                >
                  <span tw="mb-24" css={[TWText.body]}>
                    {name}
                  </span>
                </a>
              </ListItem>
            );
          })}
          <div tw="flex justify-between">
            {Object.entries(SOCIALS).map(
              ([id, { icon, link, fathomEventCode }]) => (
                <ListItem
                  variants={mobileItemVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  key={id}
                  css={[
                    isOpen
                      ? tw`pointer-events-auto`
                      : tw`pointer-events-none select-none`,
                  ]}
                >
                  <a
                    href={link}
                    onClick={() => trackGoal(fathomEventCode, 0)}
                    tabIndex={isHidden ? -1 : 0}
                    aria-hidden={isHidden ? "true" : undefined}
                    target="_blank"
                    css={[
                      isOpen
                        ? tw`pointer-events-auto`
                        : tw`pointer-events-none select-none`,
                    ]}
                    tw="mr-16"
                    rel="noreferrer"
                  >
                    {icon}
                  </a>
                </ListItem>
              )
            )}
          </div>
        </List>
      </Background>
    </Container>
  );
};

const List = styled(motion.ul)`
  top: 25vh;
  left: 0;
  padding: 0px 25%;
  position: absolute;
  width: 100%;
`;

const ListItem = styled(motion.li)`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  cursor: pointer;
`;

const Container = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
`;

const Background = styled(motion.div)`
  position: relative;
  width: 100vw;
  height: calc(100vh + ${NAVBAR_HEIGHT_PX}px);
  background: ${NAVBAR_COLOR};
`;

export default MobileMenu;
