import React from "react";
import tw, { styled } from "twin.macro";

import { SignImg } from "src/assets/img";
import { TWText } from "src/styles";
import "styled-components/macro";

type TProps = { title: string; body: string };

export const Workshop: React.FC<TProps> = (props: TProps) => {
  const { title, body } = props;
  const titlePadding = title.length >= 30 ? tw`pt-16` : tw`pt-28`;
  const bodyPadding = title.length >= 30 ? tw`pb-8` : tw`pb-16`;

  return (
    <WorkshopSign tw="relative hover:(scale-110 transition-delay[120ms] transition-all ease-in-out) mb:(scale-[0.8] hover:scale-90)">
      <SignImg tw="object-cover" />
      <div
        css={titlePadding}
        tw="absolute flex flex-col z-10 inset-0 w-full h-full px-28"
      >
        <p css={[TWText.subheadingWorkshop, bodyPadding]}>{title}</p>
        <p css={[TWText.bodyWorkshop]}>{body}</p>
      </div>
    </WorkshopSign>
  );
};

const WorkshopSign = styled.section`
  max-width: 335px;
  &:hover: {
    max-width: (335px * 1.1);
  }
`;
