export enum SectionId {
  LANDING = "landing",
  ABOUT = "about",
  SCHEDULE = "schedule",
  JUDGES = "judges",
  HEROES = "heroes",
  SPONSORS = "sponsors",
  PRIZES = "prizes",
  FAQ = "faq",
  FOOTER = "footer",
  WORKSHOPS = "workshops",
}
