import React from "react";
import ReactDOM from "react-dom";
import { createGlobalStyle, css } from "styled-components";
import { GlobalStyles } from "twin.macro";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const fonts = css`
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url("/fonts/Poppins-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-display: fallback;
    src: url("/fonts/Poppins-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins";
    font-style: italic;
    font-weight: 500;
    font-display: fallback;
    src: url("/fonts/Poppins-MediumItalic.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url("/fonts/Poppins-SemiBold.ttf") format("truetype");
  }
  @font-face {
    font-family: "Museo Slab";
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url("/fonts/Museo_Slab_700.otf") format("opentype");
  }
`;

const resetStyles = css`
  html {
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    box-sizing: border-box;
  }
  body {
    background-color: #082040;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    cursor: url("/img/marshmallow-cursor.svg") 0 0, auto;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  body > div:first-of-type,
  div#__next,
  div#__next > div {
    height: 100%;
  }
`;

const CustomStyles = createGlobalStyle`
  ${fonts}
  ${resetStyles}
`;

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <CustomStyles />
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
