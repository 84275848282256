import "./App.css";
import React from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";

import { QA } from "./components";
import Home from "./pages/Home";
import Playground from "./pages/Playground";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="playground" element={<Playground />} />
        </Routes>
        {/* <QA /> */}
      </Router>
    </>
  );
}

export default App;
