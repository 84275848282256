import { Tab } from "@headlessui/react";
import React, { useState } from "react";
import tw from "twin.macro";

import { TWText } from "src/styles";

import scheduleData from "./data/scheduleData";
import { EventRow } from "./EventRow";
import "styled-components/macro";

export const ScheduleTab: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const generateTabButtonStyle = (buttonNum: number) => {
    return selectedIndex === buttonNum ? tw`bg-secondary-brown1` : ``;
  };

  return (
    <div tw="w-full max-w-[1200px] m-auto mt-70 mb-130 md:(px-112 mb-96) tb:(px-48 mb-80) lm:(px-0 mt-48)">
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List>
          <Tab
            type="button"
            tw="inline-block w-1/3 h-56 px-8 py-2 rounded-t-16 text-20 text-white font-medium bg-secondary-brown3 hover:bg-secondary-brown1 focus:bg-secondary-brown1 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            css={generateTabButtonStyle(0)}
          >
            Day 1
          </Tab>
          <Tab
            type="button"
            tw="inline-block w-1/3 h-56 px-8 py-2 rounded-t-16 text-20 text-white font-medium bg-secondary-brown2 hover:bg-secondary-brown1 focus:bg-secondary-brown1 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            css={generateTabButtonStyle(1)}
          >
            Day 2
          </Tab>
          <Tab
            type="button"
            tw="inline-block w-1/3 h-56 px-8 py-2 rounded-t-16 text-20 text-white font-medium bg-secondary-brown3 hover:bg-secondary-brown1 focus:bg-secondary-brown1 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
            css={generateTabButtonStyle(2)}
          >
            Day 3
          </Tab>
        </Tab.List>
        <Tab.Panels tw="bg-secondary-brown1 rounded-b-16">
          {scheduleData.map(({ id, date, events }) => (
            <Tab.Panel key={id}>
              <div tw="px-28 pb-28">
                <div
                  css={[TWText.dateHeading]}
                  tw="uppercase text-white pt-25 pb-16"
                >
                  {date}
                </div>
                <div tw="overflow-y-auto min-h-[590px]">
                  {events.map(({ name, startTime, endTime }, idx) => (
                    <EventRow
                      key={idx}
                      name={name}
                      startTime={startTime}
                      endTime={endTime}
                      shouldDisplayBorder={!(idx === events.length - 1)}
                    />
                  ))}
                </div>
              </div>
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
