import React from "react";

import "twin.macro";
import "styled-components/macro";
import Layout from "src/components/base/Layout";
import About from "src/sections/About";
import FAQ from "src/sections/FAQ";
import Footer from "src/sections/Footer";
import HeroSection from "src/sections/Hero";
import Judges from "src/sections/Judges";
import Navbar from "src/sections/Navbar";
import Prizes from "src/sections/Prizes";
import Schedule from "src/sections/Schedule";
import Sponsors from "src/sections/Sponsors";
import Workshops from "src/sections/Workshops";

const Home: React.FC = () => (
  <Layout>
    <Navbar />
    <HeroSection />
    <About />
    <Schedule />
    <Judges />
    <Workshops />
    <Sponsors />
    <Prizes />
    <FAQ />
    <Footer />
  </Layout>
);

export default Home;
