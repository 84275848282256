import React from "react";
import tw, { styled } from "twin.macro";

import {
  FireflySwarmLeftImg,
  FireflySwarmRightImg,
  TinderImg,
  CanadianTireImg,
  PatagoniaImg,
  ArcteryxImg,
  BlackberryImg,
  TwitterImg,
} from "src/assets/img";
import ContentWrapper from "src/components/base/Layout/ContentWrapper";
import SectionWrapper from "src/components/base/Layout/SectionWrapper";
import { SectionId } from "src/constants/section";
import { TWText } from "src/styles";
import { deviceBreakpoints } from "src/utils/responsive";
import "styled-components/macro";

const Sponsors: React.FC = () => (
  <SectionWrapper
    id={SectionId.SPONSORS}
    tw="bg-primary-blue2 overflow-visible"
  >
    <FireflySwarmLeftImg tw="md:hidden absolute left-[-660px] top-[170px]" />
    <FireflySwarmRightImg tw="md:hidden absolute right-[-730px] top-[-125px]" />
    <ContentWrapper>
      <Section tw="relative overflow-hidden m-auto text-center">
        <h1 css={[TWText.h1]} tw="margin-top[200px] mb-16 tb:(mt-64 mb-72)">
          Sponsors
        </h1>
        <div tw="grid grid-cols-3 gap-y-[48px] gap-x-0 margin-bottom[209px] tb:(flex flex-wrap justify-around gap-y-32 margin-bottom[140px])">
          <LogoWrapper tw="tb:(-my-48)">
            <img src={TinderImg} alt={"Tinder Logo"} />
          </LogoWrapper>
          <LogoWrapper>
            <img src={CanadianTireImg} alt={"Canadian Tire Logo"} />
          </LogoWrapper>
          <LogoWrapper tw="tb:(-my-120)">
            <img src={PatagoniaImg} alt={"Patagonia Logo"} />
          </LogoWrapper>
          <LogoWrapper>
            <img src={ArcteryxImg} alt={"Arcteryx Logo"} />
          </LogoWrapper>
          <LogoWrapper>
            <img src={BlackberryImg} alt={"Blackberry Logo"} />
          </LogoWrapper>
          <LogoWrapper>
            <img src={TwitterImg} alt={"Twitter Logo"} />
          </LogoWrapper>
        </div>
      </Section>
    </ContentWrapper>
  </SectionWrapper>
);

const Section = styled.section`
  max-width: 1080px;
  @media (max-width: ${deviceBreakpoints.tablet}px) {
    max-width: 540px;
  }
`;

const LogoWrapper = styled.div(
  tw`flex items-center justify-center tb:scale-[0.8]`
);

export default Sponsors;
