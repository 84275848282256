import tw, { css } from "twin.macro";

export const TWText = {
  // Unfixed : https://github.com/tailwindlabs/tailwindcss/issues/2920
  title: tw`font-heading font-weight[700] text-72 text-white leading-normal tb:(text-48) mb:(text-36!)`,
  h1: tw`font-heading font-weight[900] text-48 text-white leading-normal tb:(text-36) lm:(text-32!)`,
  h2: tw`font-body font-weight[600] text-36 text-white leading-normal tb:(text-32) lm:(text-28! leading-relaxed)`,
  subheading: tw`font-body font-weight[600] text-24 text-white leading-normal tb:(text-16 leading-relaxed)`,
  heroEyebrow: tw`font-body font-weight[400] text-24 tracking-[.22em] text-white leading-normal lm:(text-16!)`,
  h3: tw`font-body font-weight[600] text-18 text-white leading-normal tb:(text-24 leading-relaxed) lm:(text-18!) mb:(text-16!)`,
  body: tw`font-body font-weight[500] text-16 text-white leading-loose tb:(text-14 leading-extra)`,
  cta: tw`font-body font-weight[normal] text-18 text-white leading-normal tb:(text-16 leading-relaxed)`,
  ctaDark: tw`font-body font-weight[normal] text-18 text-black leading-normal tb:(text-16 leading-relaxed)`,
  link: tw`text-blue`,
  caption: tw`font-body font-weight[normal] italic text-18 text-white leading-extra tb:(text-12)`,
  emphasis: tw`font-body font-weight[600] text-18 text-white leading-normal tb:(text-18 leading-relaxed)`,
  helper: tw`font-body font-weight[normal] text-14 text-white leading-normal tb:(text-14 leading-relaxed) lm:(text-14!) mb:(text-14!)`,
  dateHeading: tw`font-heading font-weight[700] text-24 tracking-wide leading-extra`,
  eventTitle: tw`font-body font-weight[500] leading-extra text-16 lm:(text-14! leading-relaxed) mb:(leading-normal)`,
  eventDateTitle: tw`font-body font-normal leading-extra text-16 lm:(text-14!)`,
  subheadingWorkshop: tw`font-body font-weight[600] text-24 text-white leading-normal`,
  bodyWorkshop: tw`font-body font-weight[400] text-14 text-white leading-relaxed`,
};

export const TWTransition = {
  hover: tw`hover:opacity-80 transition-all duration-100 ease-linear`,
};

export const TWGradient = {
  hero: css`
    background: linear-gradient(
      180deg,
      #3d6db9 0%,
      #1e223c 81.15%,
      #082040 100%
    );
  `,
};
